import React, { useState, useEffect } from 'react';
import { addDoc, collection, serverTimestamp } from 'firebase/firestore';
import { db } from './firebase';
import styles from './AudioChallenge.module.css';  

export default function AudioPlayer({ audioUrl, truthTranscript, sourceFileId, setUserScore, setUserListens, isHardMode, setUserTranscript }) {
  const [audio, setAudio] = useState(null);
  const [numberOfListens, setNumberOfListens] = useState(0);
  const [playing, setPlaying] = useState(false);
  const [userTranscript, setLocalUserTranscript] = useState(''); // Changed this to local state
  const [computedWer, setComputedWer] = useState(-1);
  const [isSubmitting, setIsSubmitting] = useState(false); // State for tracking form submission
  const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

  const isProduction = process.env.REACT_APP_ENV === 'production';
  const submitCollectionName = isProduction ? 'submitCollection-prod' : 'submitCollection';

  useEffect(() => {

    setNumberOfListens(0);
    setPlaying(false);
    setLocalUserTranscript('');
    setComputedWer(-1);
    setIsSubmitting(false);

    if (audioUrl) {
      const newAudio = new Audio(audioUrl);
      newAudio.addEventListener('error', (e) => {
        console.error('Error loading audio:', e);
      });
      setAudio(newAudio);
    }
  }, [audioUrl]);

  const togglePlayPause = () => {
    if (!audio) {
      console.error('Audio element not initialized');
      return;
    }
    if (isHardMode && numberOfListens >= 1) {
      console.log('Hard mode: only one listen allowed');
      return;
    }
    setPlaying(!playing);
    setNumberOfListens((prev) => prev + 1);
  };

  useEffect(() => {
    setUserListens(numberOfListens);
  }, [numberOfListens, setUserListens]);

  useEffect(() => {
    if (audio) {
      playing ? audio.play() : audio.pause();
    }
  }, [playing, audio]);

  useEffect(() => {
    if (audio) {
      audio.addEventListener('ended', () => setPlaying(false));
      return () => {
        audio.removeEventListener('ended', () => setPlaying(false));
      };
    }
  }, [audio]);

  const handleSubmit = async (event) => {
    event.preventDefault();
    setIsSubmitting(true);

    try {
      const response = await fetch(`${API_BASE_URL}/compute-wer`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          truthTranscript: truthTranscript,
          userTranscript: userTranscript, // Send the user's transcript
        }),
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const data = await response.json();
      const parsedWer = parseFloat(data[0].wer);

      setComputedWer(parsedWer);
      setUserScore(parsedWer);

      if (parsedWer !== -1) {
        await saveUserTranscript(parsedWer);
        setUserTranscript(userTranscript); // Pass the transcript to the parent component
      }
    } catch (error) {
      console.error('A problem occurred with the fetch operation:', error);
    } finally {
      setIsSubmitting(false); // Set the state back to false after the operation completes
    }
  };

  const saveUserTranscript = async (computedWer) => {
    await addDoc(collection(db, submitCollectionName), {
      userId: localStorage.getItem('userId'),
      sourceFileUrl: audioUrl,
      sourceFileId: sourceFileId,  
      userTranscript: userTranscript,
      numberOfListens: numberOfListens,
      computedWer: computedWer,
      timeSubmitted: serverTimestamp(),
    });
  };

  return (
    <div>
      <button 
        className={styles.playButton} 
        onClick={togglePlayPause}
        disabled={isHardMode && numberOfListens >= 1} /* Disable button if no plays left in hard mode */
      >
        {isHardMode && numberOfListens >= 1 && !playing ? 'No plays left' : playing ? 'Playing...' : 'Play Audio'}
      </button>
      <p><i>Remember, you'll only be able to listen once!</i></p>
      <form onSubmit={handleSubmit}>
        <textarea
          value={userTranscript}
          onChange={(e) => setLocalUserTranscript(e.target.value)} // Update local state for user input
          placeholder="Type what you hear as accurately as possible"
          rows="4"
          cols="50"
        ></textarea>
        <button type="submit" className={styles.submitButton}>
          {isSubmitting ? 'Submitting...' : 'Submit'}
        </button>
      </form>
      {computedWer !== -1 && <div>WER: {computedWer}</div>}
    </div>
  );
}
