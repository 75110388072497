import React from 'react';
import styles from './HomePage.module.css';

export default function HomePage({ startGame }) {
  return (
    <div className={styles.HomePage}>
      <h1>Human vs AI: Speech-to-Text</h1>
      <p>
        AI Speech-to-Text has gotten good. Some might say superhuman. Can you beat the AI? How will you compare to other humans?
        <br />
        <br />
        The game is simple: listen to a short audio clip and type what you hear. See if you can beat the accuracy of the AI (and other humans)! 
        <br />
        <br />
        There are 5 rounds. 5 audio clips. 5 chances to beat the AI.
      </p>
      <p>
        <strong>NOTE:</strong> You can only listen to each clip once. This is the true test vs AI!
      </p>
      <button onClick={() => startGame('hard')}>Start!</button>
      <p>Average time to complete game: 3 minutes</p>
    </div>
  );
}
