// import React, { useEffect, useState } from 'react';
// import AudioPlayer from './AudioPlayer';
// import SourceFileStats from './SourceFileStats';
// import { collection, query, where, getDocs } from 'firebase/firestore';
// import { db } from './firebase';
// import styles from './AudioChallenge.module.css';

// export default function AudioChallenge({ challengeNumber, gameMode, nextChallenge, updateUserScore }) { // Accept updateUserScore as a prop
//   const [audioUrl, setAudioUrl] = useState('');
//   const [truthTranscript, setTruthTranscript] = useState('');
//   const [aiTranscript, setAiTranscript] = useState('');
//   const [userTranscript, setUserTranscript] = useState(''); 
//   const [userScore, setUserScore] = useState(null);
//   const [userListens, setUserListens] = useState(null);
//   const [asrWer, setAsrWer] = useState(null);
//   const [sourceFileId, setSourceFileId] = useState(null);
//   const [message, setMessage] = useState('');
//   const [aiAccuracy, setAiAccuracy] = useState(null);
//   const [userAccuracy, setUserAccuracy] = useState(null);

//   useEffect(() => {

//     setUserListens(null);
//     setUserScore(null);

//     const fetchAudioData = async () => {
//       try {
//         const q = query(collection(db, 'sourceFiles'), where('audioId', '==', challengeNumber));
//         const querySnapshot = await getDocs(q);

//         if (!querySnapshot.empty) {
//           const doc = querySnapshot.docs[0];
//           const audioData = querySnapshot.docs[0].data();
//           setTruthTranscript(audioData.truthTranscript);
//           setAiTranscript(audioData.asrTranscript);
//           setAudioUrl(audioData.audioFile);
//           setAsrWer(audioData.asrWer);
//           setSourceFileId(doc.id);

//           console.log("URL: ", audioUrl);
//         }
//       } catch (error) {
//         console.error("Error fetching document:", error);
//       }
//     };

//     fetchAudioData();
//   }, [challengeNumber]);

//   useEffect(() => {
//     if (asrWer !== null && userScore !== null) {
//       setAiAccuracy(100 - asrWer * 100);
//       setUserAccuracy(100 - userScore * 100);
//       if (userScore < asrWer) {
//         setMessage('Nice! You beat the AI.');
//       } else if (userScore === asrWer) {
//         setMessage('You and the AI tied!');
//       } else {
//         setMessage('Dang! AI won this time.');
//       }

//       // After scoring is complete, update the userScore and move to the next challenge
//       // console.log('Calling updateUserScore with score:', userScore);
//       updateUserScore(1 - userScore); // Pass the score to App.js
//     }
//   }, [asrWer, userScore]);

//   return (
//     <div div className={styles.AudioChallenge}>
//       <h1>Human vs AI: Speech-to-Text</h1>
//       <h2>Audio file {challengeNumber}/5</h2>

//       {userScore === null && (
//       <div>
//         <p>Can you beat the AI's {asrWer !== null && asrWer !== undefined ? `${(100 - asrWer * 100).toFixed(0)}%` : 'N/A'} accurate score?</p>
//         {audioUrl ? (
//           <AudioPlayer
//             audioUrl={audioUrl}
//             truthTranscript={truthTranscript}
//             sourceFileId={sourceFileId}
//             setUserScore={setUserScore}
//             setUserListens={setUserListens}
//             isHardMode={gameMode === 'hard'}
//             setUserTranscript={setUserTranscript}
//           />
//         ) : 'Loading...'}
//       </div>
//       )}

//         {userScore !== null && userListens !== null && (
//             <div>
//               <br />
//               <p><strong>{message}</strong></p>
//               <p><strong>Your accuracy: </strong> {Math.round(userAccuracy)}%</p>
//               <p><strong>AI accuracy: </strong>{Math.round(aiAccuracy)}%</p>
//               <p><strong>The actual transcript: </strong>{truthTranscript}</p>
//               <p><strong>What the AI wrote: </strong>{aiTranscript}</p>
//               <p><strong>What you wrote: </strong>{userTranscript}</p>
//               <SourceFileStats
//                 sourceFileUrl={audioUrl}
//                 userScore={userScore}
//                 asrWer={asrWer}
//               />
//               <button onClick={nextChallenge}>Next Challenge</button>
//             </div>
//           )}
//     </div>
//   );
// }

import React, { useEffect, useState } from 'react';
import AudioPlayer from './AudioPlayer';
import SourceFileStats from './SourceFileStats';
import { collection, query, where, getDocs } from 'firebase/firestore';
import { db } from './firebase';
import styles from './AudioChallenge.module.css';

export default function AudioChallenge({ challengeNumber, gameMode, nextChallenge, updateUserScore }) { 
  const [audioUrl, setAudioUrl] = useState('');
  const [truthTranscript, setTruthTranscript] = useState('');
  const [aiTranscript, setAiTranscript] = useState('');
  const [userTranscript, setUserTranscript] = useState(''); 
  const [userScore, setUserScore] = useState(null);
  const [userListens, setUserListens] = useState(null);
  const [asrWer, setAsrWer] = useState(null);
  const [sourceFileId, setSourceFileId] = useState(null);
  const [message, setMessage] = useState('');
  const [aiAccuracy, setAiAccuracy] = useState(null);
  const [userAccuracy, setUserAccuracy] = useState(null);
  const [isReplayEnabled, setIsReplayEnabled] = useState(false); // State to enable replay

  useEffect(() => {
    setUserListens(null);
    setUserScore(null);

    const fetchAudioData = async () => {
      try {
        const q = query(collection(db, 'sourceFiles'), where('audioId', '==', challengeNumber));
        const querySnapshot = await getDocs(q);

        if (!querySnapshot.empty) {
          const doc = querySnapshot.docs[0];
          const audioData = querySnapshot.docs[0].data();
          setTruthTranscript(audioData.truthTranscript);
          setAiTranscript(audioData.asrTranscript);
          setAudioUrl(audioData.audioFile);
          setAsrWer(audioData.asrWer);
          setSourceFileId(doc.id);
        }
      } catch (error) {
        console.error("Error fetching document:", error);
      }
    };

    fetchAudioData();
  }, [challengeNumber]);

  useEffect(() => {
    if (asrWer !== null && userScore !== null) {
      setAiAccuracy(100 - asrWer * 100);
      setUserAccuracy(100 - userScore * 100);
      if (userScore < asrWer) {
        setMessage('Nice! You beat the AI.');
      } else if (userScore === asrWer) {
        setMessage('You and the AI tied!');
      } else {
        setMessage('Dang! AI won this time.');
      }

      updateUserScore(1 - userScore); // Pass the score to App.js

      // Enable replay once score is submitted
      setIsReplayEnabled(true);
    }
  }, [asrWer, userScore]);

  // Replay functionality
  const handleReplay = () => {
    const audioElement = new Audio(audioUrl); // Create new audio instance for replay
    audioElement.play(); // Play the audio
  };

  return (
    <div className={styles.AudioChallenge}>
      <h1>Human vs AI: Speech-to-Text</h1>
      <h2>Audio file {challengeNumber}/5</h2>

      {userScore === null && (
        <div>
          <p>Can you beat the AI's {asrWer !== null && asrWer !== undefined ? `${(100 - asrWer * 100).toFixed(0)}%` : 'N/A'} accurate score?</p>
          {audioUrl ? (
            <AudioPlayer
              audioUrl={audioUrl}
              truthTranscript={truthTranscript}
              sourceFileId={sourceFileId}
              setUserScore={setUserScore}
              setUserListens={setUserListens}
              isHardMode={gameMode === 'hard'}
              setUserTranscript={setUserTranscript}
            />
          ) : 'Loading...'}
        </div>
      )}

      {userScore !== null && userListens !== null && (
        <div>
          <br />
          <p><strong>{message}</strong></p>
          <p><strong>Your accuracy: </strong> {Math.round(userAccuracy)}%</p>
          <p><strong>AI accuracy: </strong>{Math.round(aiAccuracy)}%</p>
          <p><strong>The actual transcript: </strong>{truthTranscript}</p>
          <p><strong>What the AI wrote: </strong>{aiTranscript}</p>
          <p><strong>What you wrote: </strong>{userTranscript}</p>
          {isReplayEnabled && (
              <button onClick={handleReplay} className={styles.replayButton}>Replay Audio</button>
            )}
          <SourceFileStats
            sourceFileUrl={audioUrl}
            userScore={userScore}
            asrWer={asrWer}
          />
          <div>
            <button onClick={nextChallenge}>Next Challenge</button>
          </div>
        </div>
      )}
    </div>
  );
}
