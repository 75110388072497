import React, { useState, useEffect, useCallback } from 'react';
import HomePage from './HomePage';
import AudioChallenge from './AudioChallenge';
import GameOverScreen from './GameOverScreen';
import { collection, getDocs, addDoc, query, orderBy, limit, serverTimestamp } from 'firebase/firestore';
import { db } from './firebase'; // Import Firebase DB
import styles from './App.module.css';


// Function to fetch AI's average WER score from sourceFiles and convert to accuracy
const fetchAIAverageScore = async () => {
  try {
    const sourceFilesSnapshot = await getDocs(collection(db, 'sourceFiles')); // Fetch source files
    const aiScores = sourceFilesSnapshot.docs.map(doc => doc.data().asrWer); // Extract asrWer values

    if (aiScores.length > 0) {
      const averageAIScore = aiScores.reduce((a, b) => a + b, 0) / aiScores.length;
      //console.log('AI WER Scores:', aiScores);  // Log AI WER scores
      //console.log('Average AI Score:', 100 - (averageAIScore * 100));  // Log converted accuracy
      return 100 - (averageAIScore * 100); // Convert WER to accuracy
    }
  } catch (error) {
    console.error('Error fetching AI scores:', error);
  }
  return null; // In case of an error, return null
};

export default function App() {
  const [gameMode, setGameMode] = useState(null); // 'easy' or 'hard'
  const [currentChallenge, setCurrentChallenge] = useState(0);
  const totalChallenges = 5;
  const [userScores, setUserScores] = useState([]); // Store user scores
  const [leaderboard, setLeaderboard] = useState([]);
  const [averageUserScore, setAverageUserScore] = useState(null);
  const [averageGlobalScore, setAverageGlobalScore] = useState(null);
  const [aiScore, setAIScore] = useState(null); // State for AI score
  const [userRank, setUserRank] = useState(null);
  const [percentageBeaten, setPercentageBeaten] = useState(null);

 
  const isProduction = process.env.REACT_APP_ENV === 'production';
  const leaderboardCollectionName = isProduction ? 'leaderboard-prod' : 'leaderboard';


  // Function to update userScores after each challenge
  const updateUserScore = useCallback((score) => {
    //console.log('New score to add:', score);

    setUserScores((prevScores) => {
      const updatedScores = [...prevScores, score];
      //console.log('Updated Scores:', updatedScores);
      return updatedScores;
    });
  }, []); // Empty dependency array ensures this function doesn't get recreated

  // Calculate average user score at game end
  useEffect(() => {
    if (currentChallenge > totalChallenges) {
      const totalUserScore = userScores.reduce((a, b) => a + b, 0);
      const avgScore = totalUserScore / totalChallenges;
      setAverageUserScore(avgScore);
      //console.log('Average User Score:', avgScore);
    }
  }, [currentChallenge, userScores]);

  // Fetch and calculate the global average score (before the user submits)
  useEffect(() => {
    if (currentChallenge > totalChallenges) { // After the game ends
      const fetchGlobalAverageScore = async () => {
        const allScoresQuery = query(collection(db, leaderboardCollectionName));
        const allScoresSnapshot = await getDocs(allScoresQuery);
        const allScores = allScoresSnapshot.docs.map(doc => doc.data().averageScore);
        
        if (allScores.length > 0) {
          const globalAvgScore = allScores.reduce((a, b) => a + b, 0) / allScores.length;
          setAverageGlobalScore(globalAvgScore);
          //console.log('Global Average Score:', globalAvgScore);
        }
      };
      
      fetchGlobalAverageScore();
    }
  }, [currentChallenge]); // This runs when the game ends

  // Function to generate a new random user ID
  const generateRandomUserId = () => {
    return 'user-' + Math.random().toString(36).substr(2, 9);
  };

  // Function to start the game and load AI score
  const startGame = async (mode) => {
    setGameMode(mode);
    setCurrentChallenge(1);
    setUserScores([]); // Reset user scores when the game starts

    // Generate a new random user ID when the game starts
    const newUserId = generateRandomUserId();
    localStorage.setItem('userId', newUserId);
    // console.log('New User ID set:', newUserId);


    // Load AI score when the game starts
    const averageAIScore = await fetchAIAverageScore();
    setAIScore(averageAIScore); // Set the AI score in state
    //console.log('AI Score loaded at game start:', averageAIScore); // Log AI score
  };

  const nextChallenge = () => {
    setCurrentChallenge((prev) => prev + 1);
  };

  const handleGameEnd = async (userName) => {
    const userId = localStorage.getItem('userId');
    const totalUserScore = userScores.reduce((a, b) => a + b, 0);
    const avgUserScore = totalUserScore / totalChallenges;
    // console.log('Total User Score: ', totalUserScore, 'Total Challenges: ', totalChallenges);
  
    // Save user score to Firebase
    await addDoc(collection(db, leaderboardCollectionName), {
      userId: userId,
      userName: userName,
      averageScore: avgUserScore,
      timestamp: serverTimestamp(),
    });
  
    // Fetch all leaderboard data for calculating the user's rank
    const allScoresQuery = query(collection(db, leaderboardCollectionName), orderBy('averageScore', 'desc'));
    const allScoresSnapshot = await getDocs(allScoresQuery);
    const leaderboardData = allScoresSnapshot.docs.map(doc => doc.data());
  
    // Find the rank of the user in the leaderboard
    const userRank = leaderboardData.findIndex(user => user.userId === userId) + 1;
    const totalUsers = leaderboardData.length;
  
    // Calculate percentage of users beaten
    const percentageBeaten = ((totalUsers - userRank) / totalUsers) * 100;
  
    // Fetch only the top 10 for leaderboard display
    const top10Query = query(collection(db, leaderboardCollectionName), orderBy('averageScore', 'desc'), limit(10));
    const top10Snapshot = await getDocs(top10Query);
    const top10Data = top10Snapshot.docs.map(doc => doc.data());
  
    // Set leaderboard to top 10 and also pass rank and percentage
    setLeaderboard(top10Data);
    setUserRank(userRank);
    setPercentageBeaten(percentageBeaten);
  };
  

  return (
    <div>
      {gameMode ? (
        currentChallenge <= totalChallenges ? (
          <AudioChallenge
            challengeNumber={currentChallenge}
            gameMode={gameMode}
            nextChallenge={nextChallenge}
            updateUserScore={updateUserScore} // Pass this function to AudioChallenge
          />
        ) : (
          <GameOverScreen
            userScores={userScores}
            handleGameEnd={handleGameEnd}
            leaderboard={leaderboard}
            averageGlobalScore={averageGlobalScore}
            averageUserScore={averageUserScore}
            aiScore={aiScore}
            userRank={userRank}   // Pass the rank
            percentageBeaten={percentageBeaten}   // Pass the percentage beaten
          />
        )
      ) : (
        <HomePage startGame={startGame} />
      )}
      {/* Add footer with AssemblyAI logo */}
      <footer className={styles.footer}>
        <p><strong><i>AI powered by:</i></strong></p>
        <a href="https://www.assemblyai.com/" target="_blank" rel="noopener noreferrer">
          <img src="/AssemblyAI_logo.png" alt="AssemblyAI logo" className={styles.logo} />
        </a>
      </footer>
    </div>
  );
}




