import React, { useState, useEffect } from 'react';
import styles from './GameOverScreen.module.css';


export default function GameOverScreen({ userScores, handleGameEnd, leaderboard, averageGlobalScore, averageUserScore, aiScore, userRank, percentageBeaten }) {
    const [userName, setUserName] = useState('');
    const [isSubmitted, setIsSubmitted] = useState(false);
    const [message, setMessage] = useState(''); // State to hold the message
  
    // Helper function to format a decimal score as a percentage
    const formatPercentage = (score) => {
      return `${(score * 100).toFixed(0)}%`; // Multiply by 100 and format to 2 decimal places
    };
  
    // Determine if the user beat the AI, tied, or lost based on the scores
    useEffect(() => {
      if (averageUserScore !== null && aiScore !== null) {
        if (averageUserScore > aiScore) {
          setMessage('Nice! You beat the AI.');
        } else if (averageUserScore === aiScore) {
          setMessage('You and the AI tied!');
        } else {
          setMessage('Dang! AI won this time.');
        }
      }
    }, [averageUserScore, aiScore]); // Recalculate the message when user or AI scores change
  
    const handleSubmit = () => {
      if (userName) {
        handleGameEnd(userName);
        setIsSubmitted(true);
      }
    };
  
    return (
      <div className={styles.GameOverScreen}>
        <h1>Game Over!</h1>
        <h2>{message}</h2>
  
        {/* Display the user's average score */}
        <p><strong>Your average score: </strong>{averageUserScore !== null ? formatPercentage(averageUserScore) : 'Calculating...'}</p>
  
        {/* Display the AI's score as a percentage */}
        <p><strong>Average AI score: </strong>{aiScore !== null ? formatPercentage(aiScore / 100) : 'Loading AI score...'}</p>

        {/* Display the global average score */}
        <p><strong>Average score of all other humans: </strong>{averageGlobalScore !== null ? formatPercentage(averageGlobalScore) : 'Calculating...'}</p>
  
        {!isSubmitted ? (
            <div>
                <p>Submit your name to see your overall ranking and to see if you had a Top 10 score!</p>
                
                {/* Add container to wrap input and button */}
                <div className={styles.formContainer}>
                    <input 
                    type="text"
                    value={userName}
                    onChange={(e) => setUserName(e.target.value)}
                    placeholder="Enter your name"
                    className={styles.input}
                    />
                    <button className={styles.button} onClick={handleSubmit}>Submit to Leaderboard</button>
                </div>
            </div>
        ) : (
          <div>
            {/* Display the user's rank and percentage of other users beaten */}
            <p><strong>Your rank: </strong>{userRank !== null ? `#${userRank}` : 'Calculating rank...'}</p>
            <p>You beat {percentageBeaten !== null ? `${percentageBeaten.toFixed(0)}%` : 'Calculating...'} of all other humans!</p>
            <h2>Top 10 Leaderboard</h2>
            <ol>
              {leaderboard.map((user, index) => (
                <li key={index}>{user.userName}: {formatPercentage(user.averageScore)}</li>
              ))}
            </ol>
          </div>
        )}
      </div>
    );
  }
  
