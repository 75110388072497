import React, { useEffect, useState, useRef } from 'react';
import { Bar } from 'react-chartjs-2';
import { Chart, registerables } from 'chart.js';
import annotationPlugin from 'chartjs-plugin-annotation';
import 'chart.js/auto'; // Import Chart.js

Chart.register(...registerables);
Chart.register(annotationPlugin);

function SourceFileStats({ sourceFileUrl, userScore, asrWer }) {
    const [data, setData] = useState({ allData: [], distribution: { labels: [], values: [] }, averageWer: 0 });
    const [barColors, setBarColors] = useState([]); // State to store bar colors
    const [isDataLoaded, setIsDataLoaded] = useState(false); // Track data loading status
    const [showTiedLegend, setShowTiedLegend] = useState(false); // Track if tied legend should be shown
    const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

    useEffect(() => {
        if (sourceFileUrl) {
            setIsDataLoaded(false); // Set data loading state to false while fetching
            fetch(`${API_BASE_URL}/compute-sourcefile-stats?sourceFileUrl=${encodeURIComponent(sourceFileUrl)}`)
                .then(response => response.json())
                .then(data => {
                    setData(data);
                    generateBarColors(data); // Generate bar colors once data is fetched
                    setIsDataLoaded(true); // Set data loading state to true once loaded
                })
                .catch(error => {
                    console.error('Error fetching data:', error);
                    setIsDataLoaded(true); // Even in case of error, we need to stop the loading state
                });
        }
    }, [sourceFileUrl]);

    const generateBarColors = (data) => {
        // Create a map to count the frequency of each accuracy score
        const frequencyMap = data.allData.reduce((acc, curr) => {
            const accuracy = Math.round((1 - curr.wer) * 100);
            if (!acc[accuracy]) {
                acc[accuracy] = 0;
            }
            acc[accuracy]++;
            return acc;
        }, {});

        // Create the labels (accuracy %) and the values (frequency of each score)
        const labels = Array.from({ length: 101 }, (_, i) => i); // Labels from 0 to 100

        // Round user's score and AI's accuracy for bar placement
        const userScoreRounded = Math.round((1 - userScore) * 100);
        const aiScoreRounded = Math.round((1 - asrWer) * 100);

        // Check if the user score is equal to the AI score
        const isTied = userScoreRounded === aiScoreRounded;
        setShowTiedLegend(isTied); // Update the legend state based on whether it's a tie

        // Create dataset for the bar chart
        const colors = labels.map(label => {
            if (isTied && label === userScoreRounded) {
                return 'purple'; // Tied case, show purple
            } else if (label === userScoreRounded) {
                return 'red'; // User's score is red
            } else if (label === aiScoreRounded) {
                return 'blue'; // AI's accuracy is blue
            } else {
                return 'black'; // Other human players' scores are black
            }
        });

        setBarColors(colors);
    };

    if (!isDataLoaded) return <div>Loading...</div>; // Show loading state if data is not yet loaded

    const labels = Array.from({ length: 101 }, (_, i) => i); // Labels from 0 to 100
    const values = labels.map(label => data.allData.reduce((acc, curr) => acc + (Math.round((1 - curr.wer) * 100) === label ? 1 : 0), 0)); // Map frequencies to labels

    const chartData = {
        labels: labels,
        datasets: [
            {
                label: 'Accuracy (%)',
                data: values,
                backgroundColor: barColors,
                borderWidth: 1,
            }
        ]
    };

    const options = {
        scales: {
            x: {
                title: {
                    display: true,
                    text: 'Accuracy (%)'
                },
                min: 0,
                max: 100,
                ticks: {
                    stepSize: 10
                }
            },
            y: {
                title: {
                    display: true,
                    text: '# of other humans'
                },
                beginAtZero: true,
                ticks: {
                    stepSize: 1
                }
            }
        },
        plugins: {
            legend: {
                display: true,
                labels: {
                    generateLabels: (chart) => {
                        // Dynamic legend generation based on whether scores are tied
                        if (showTiedLegend) {
                            return [
                                {
                                    text: 'Tied: Your score and AI accuracy',
                                    fillStyle: 'purple',
                                    strokeStyle: 'purple',
                                    lineWidth: 2,
                                    hidden: false
                                },
                                {
                                    text: 'Other human players',
                                    fillStyle: 'black',
                                    strokeStyle: 'black',
                                    hidden: false
                                }
                            ];
                        } else {
                            return [
                                {
                                    text: 'Your Score',
                                    fillStyle: 'red',
                                    strokeStyle: 'black',
                                    hidden: false
                                },
                                {
                                    text: 'AI accuracy',
                                    fillStyle: 'blue',
                                    strokeStyle: 'black',
                                    hidden: false
                                },
                                {
                                    text: 'Other human players',
                                    fillStyle: 'black',
                                    strokeStyle: 'black',
                                    hidden: false
                                }
                            ];
                        }
                    }
                }
            },
            tooltip: {
                callbacks: {
                    label: function(context) {
                        const accuracy = context.raw;
                        return `Frequency: ${accuracy}`;
                    }
                }
            }
        }
    };

    return (
        <div>
            <br />
            <h3>How you did versus other humans</h3>
            <Bar data={chartData} options={options} />
            <div>
                <h4>You scored higher than {100 - Math.round(getOverallPercentile(data.allData, userScore))}% of all other human players</h4>
            </div>
        </div>
    );
}

// Updated percentile calculation that excludes listens
function getOverallPercentile(data, userScore) {
    const sorted = [...data].sort((a, b) => a.wer - b.wer);
    const rank = sorted.findIndex(d => d.wer === userScore) + 1;
    return Math.round((rank / sorted.length) * 100);
}

export default SourceFileStats;
