// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getFirestore, collection } from "firebase/firestore"
import { getStorage, ref, uploadBytes, uploadString, getDownloadURL } from "firebase/storage";



// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyCudMvq8nLz6jsyXoKBkXoxvgb6isiWPhE",
  authDomain: "human-vs-ai-d648b.firebaseapp.com",
  projectId: "human-vs-ai-d648b",
  storageBucket: "human-vs-ai-d648b.appspot.com",
  messagingSenderId: "18299130179",
  appId: "1:18299130179:web:7d94fbdb685172e13b5ff6",
  measurementId: "G-EK4YEWR9YP"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
export const db = getFirestore(app)
export const storage = getStorage(app);
